<template>
  <v-app :dark="isDark">
    <v-app-bar app flat>
      <div class="d-flex align-center">
        <h1 class="text-h4">Yahtzee</h1>
      </div>
      <v-spacer></v-spacer>
      <div class="d-flex align-center">
        <v-checkbox hide-details label="Dark" v-model="isDark"></v-checkbox>
      </div>
    </v-app-bar>

    <v-main >
      <v-container>
        <div>
          <h2 class="text-h6">Upper Section</h2>
          <v-row dense>
            <v-col cols="4">
              <v-text-field
                hide-details
                dense
                label="Aces"
                outlined

                inputmode="numeric"
                pattern="[0-9]*"
                v-model.number="game.aces"
                v-min="0"
                v-max="5"
                step="1"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                hide-details
                dense
                label="Twos"
                outlined

                inputmode="numeric"
                v-min="0"
                v-max="10"
                step="2"
                v-model.number="game.twos"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                hide-details
                dense
                label="Threes"
                outlined

                inputmode="numeric"
                v-min="0"
                v-max="15"
                step="3"
                v-model.number="game.threes"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4">
              <v-text-field
                hide-details
                dense
                label="Fours"
                outlined

                inputmode="numeric"
                v-min="0"
                v-max="20"
                step="4"
                v-model.number="game.fours"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                hide-details
                dense
                label="Fives"
                outlined

                inputmode="numeric"
                v-min="0"
                v-max="25"
                step="5"
                v-model.number="game.fives"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                hide-details
                dense
                label="Sixes"
                outlined

                inputmode="numeric"
                v-min="0"
                v-max="30"
                step="6"
                v-model.number="game.sixes"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense class="text-center">
            <v-col cols="4">
              <div class="text-body-1">Score Total</div>
              <div class="text-body-1">{{ scoreTotal }}</div>
            </v-col>
            <v-col cols="4">
              <div class="text-body-1">Bonus (63)</div>
              <div class="text-body-1">{{ bonus }}</div>
            </v-col>
            <v-col cols="4">
              <div class="text-body-1">Upper Total</div>
              <div class="text-body-1">{{ upperTotal }}</div>
            </v-col>
          </v-row>
        </div>
        <div>
          <h2 class="text-h6 mt-4">Lower Section</h2>
          <v-row dense>
            <v-col cols="4">
              <v-text-field
                hide-details
                dense
                label="3 of a kind"
                outlined

                inputmode="numeric"
                v-min="0"
                v-max="30"
                step="1"
                v-model.number="game.kind3"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                hide-details
                dense
                label="4 of a kind"
                outlined

                inputmode="numeric"
                v-min="0"
                v-max="30"
                step="1"
                v-model.number="game.kind4"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                hide-details
                dense
                label="Full House (25)"
                outlined

                inputmode="numeric"
                v-min="0"
                v-max="30"
                step="1"
                v-model.number="game.fullHouse"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4">
              <v-text-field
                hide-details
                dense
                label="S Straight (30)"
                outlined

                inputmode="numeric"
                v-model.number="game.smallStraight"
                v-min="0"
                v-max="30"
                step="1"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                hide-details
                dense
                label="L Straight (40)"
                outlined

                inputmode="numeric"
                v-model.number="game.largeStraight"
                v-min="0"
                v-max="30"
                step="1"
              ></v-text-field>
            </v-col>
            <v-col cols="4">
              <v-text-field
                hide-details
                dense
                label="YAHTZEE (50)"
                outlined

                inputmode="numeric"
                v-model.number="game.yahtzee"
                v-min="0"
                v-max="30"
                step="1"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense>
            <v-col cols="4">
              <v-text-field
                hide-details
                dense
                label="Chance"
                outlined

                inputmode="numeric"
                v-model.number="game.chance"
                v-min="0"
                v-max="30"
                step="1"
              ></v-text-field>
            </v-col>
            <v-col cols="8">
              <v-text-field
                hide-details
                prepend-inner-icon="mdi-minus"
                @click:prepend-inner="
                  game.yahtzeeBonus = Math.max(game.yahtzeeBonus - 1, 0)
                "
                @click:append="game.yahtzeeBonus += 1"
                append-icon="mdi-plus"
                dense
                label="YAHTZEE BONUSES"
                outlined
                inputmode="numeric"
                v-min="0"
                v-model.number="game.yahtzeeBonus"
                readonly
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row dense  class="text-center">
            <v-col cols="6">
              <div class="text-body-1">Upper Total</div>
              <div class="text-body-1">{{ upperTotal }}</div>
            </v-col>
            <v-col cols="6">
              <div class="text-body-1">Lower Total</div>
              <div class="text-body-1">{{ lowerTotal }}</div>
            </v-col>
          </v-row>
          <v-row dense justify="center" class="text-center">
            <v-col cols="6">
              <v-sheet class="py-2" :dark="!isDark" color="primary" rounded>
                <div class="text-body-1"><strong>Grand Total:</strong> {{ grandTotal }}</div>
              </v-sheet>
            </v-col>
          </v-row>
          <v-row dense justify="center" class="text-center">
            <v-col>
              <v-dialog width="288" v-model="confirmDialog">
                <template v-slot:activator="{on, attrs}">
                  <v-btn v-bind="attrs" v-on="on" text color="primary">Reset Sheet</v-btn>
                </template>

                <v-card>
                  <v-card-title class="headline">
                    Confirm
                  </v-card-title>

                  <v-card-text>
                    Are you sure you want to reset the sheet?
                  </v-card-text>

                  <v-divider></v-divider>

                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="primary" text @click="confirmDialog = false">
                      No
                    </v-btn>
                    <v-btn color="primary" @click="reset">
                      Yes
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </v-col>
          </v-row>
        </div>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
const unpackItem = (key) => JSON.parse(window.localStorage.getItem(key))
const storeItem = (key, item) => window.localStorage.setItem(key, JSON.stringify(item))

export default {
  computed: {
    isDark: {
      get () {
        const isDark = unpackItem('isDark')
        return isDark || this.$vuetify.theme.dark
      },
      set (isDark) {
        this.$vuetify.theme.dark = isDark
      }
    },
    scoreTotal () {
      return (
        +this.game.aces +
        +this.game.twos +
        +this.game.threes +
        +this.game.fours +
        +this.game.fives +
        +this.game.sixes
      )
    },
    bonus () {
      if (this.scoreTotal < 63) return 0
      return 35
    },
    upperTotal () {
      return this.scoreTotal + this.bonus
    },
    lowerTotal () {
      let sum = this.game.kind3 + this.game.kind4 + this.game.fullHouse + this.game.smallStraight + this.game.largeStraight + this.game.chance + this.game.yahtzee
      sum += this.game.yahtzeeBonus * 100
      return sum
    },
    grandTotal () {
      return this.upperTotal + this.lowerTotal
    }
  },
  data () {
    return {
      confirmDialog: false,
      game: unpackItem('game') || {
        aces: null,
        twos: null,
        threes: null,
        fours: null,
        fives: null,
        sixes: null,
        kind3: null,
        kind4: null,
        fullHouse: null,
        smallStraight: null,
        largeStraight: null,
        yahtzee: null,
        chance: null,
        yahtzeeBonus: 0
      }
    }
  },
  methods: {
    reset () {
      this.game = {
        aces: null,
        twos: null,
        threes: null,
        fours: null,
        fives: null,
        sixes: null,
        kind3: null,
        kind4: null,
        fullHouse: false,
        smallStraight: false,
        largeStraight: false,
        yahtzee: false,
        chance: null,
        yahtzeeBonus: 0
      }
      this.confirmDialog = false
    }
  },
  directives: {
    max: {
      update (el, binding, vnode, oldVnode) {
        const value = vnode.data.model.value
        if (value === null) return
        const max = binding.value
        const clampedValue = Math.min(max, value)
        const event = new Event('input', { bubbles: true })
        const input = el.querySelector('input')
        input.value = clampedValue
        input.dispatchEvent(event)
      }
    },
    min: {
      update (el, binding, vnode, oldVnode) {
        const value = vnode.data.model.value
        if (value === null) return
        const min = binding.value
        const clampedValue = Math.max(min, value)
        const event = new Event('input', { bubbles: true })
        const input = el.querySelector('input')
        input.value = clampedValue
        input.dispatchEvent(event)
      }
    }
  },
  watch: {
    isDark (isDark) {
      storeItem('isDark', isDark)
      this.$veutify.theme.dark = isDark
    },
    game: {
      deep: true,
      handler (game) {
        storeItem('game', game)
      }
    }
  }
}
</script>
